<template>
  <div>
    <AcceptModal
      v-if="showAcceptModal"
      :rent-id="currentRentId"
      :should_start_rent_after_accepting="
        rentalDetail.should_start_rent_after_accepting
      "
      @refresh="onRefreshDetails"
      v-on:close="onAcceptModalClose"
    />
    <RejectModal
      v-if="showRejectModal"
      :rent-id="currentRentId"
      v-on:close="onRejectModalClose"
    />
    <CancelModal
      v-if="showCancelModal"
      :rent-id="currentRentId"
      @refresh="onRefreshDetails"
      v-on:close="onCancelModalClose"
    />
    <DeliverModal
      v-if="showDeliverModal"
      :rent-id="currentRentId"
      v-on:close="onDeliverModalClose"
    />

    <section
      class="
        w-full
        px-4
        py-3
        mb-2
        bg-white
        rounded-md
        shadow
        responsive-header-section
      "
    >
      <div class="flex flex-wrap items-center w-full h-full">
        <div class="items-center left-trip">
          <div
            class="items-center place-content-center back-arrow"
            @click="goBack"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
          <span>{{
            $t('components.vehicleRentalsManagement.details.headline.title', {
              id: `${$truncate(rentalDetail.id, -5)}`,
            })
          }}</span>
          <span id="myDiv" ref="myDiv"></span>
        </div>

        <div class="block lg:flex right-trip lg:items-center">
          <div class="ml-2">
            <button
              @click="$emit('download-invoice')"
              class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm
                text-14px text-oCharcoal
                focus:border-oCharcoal
                hover:border-oCharcoal
                focus:outline-none
              "
              style="height: 35px"
            >
              <div class="flex items-center">
                <svg
                  class="w-6 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                  ></path>
                </svg>
                <span class="text-gray-700">  {{
                    $t(
                      'components.vehicleRentalsManagement.details.headline.downloadInvoice'
                    )
                  }} </span>
              </div>
            </button>
          </div>
          <!-- <div class="flex items-center ml-2">
            <ExportTripBlock />
          </div> -->

          <div class="flex items-center mt-2 ml-2 lg:mt-0">
            <template
              v-if="
                rentalDetail.status === 'REQUESTED' ||
                  rentalDetail.status === 'ACCEPTED' ||
                  rentalDetail.status === 'DELIVERED' ||
                  rentalDetail.status === 'TRIP_STARTED'
              "
            >
              <div>
                <t-dropdown variant="smActions">
                  <template
                    #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                  >
                    <button
                      aria-label="Rent Status Dropdown"
                      :class="
                        `rental-status-pill-button ${getStatusVariant} focus:outline-none`
                      "
                      style="height: 35px; width: 170px"
                      aria-haspopup="true"
                      @mousedown="mousedownHandler"
                      @focus="focusHandler"
                      @blur="blurHandler"
                      @keydown="keydownHandler"
                    >
                      <span>{{ getStatusText }}</span>
                      <i class="fas fa-chevron-down" />
                    </button>
                  </template>

                  <template #default="{ hide }">
                    <div
                      @click="hide()"
                      class="py-1 mt-1 bg-white rounded-md shadow-md"
                    >
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'accept',
                          })
                        "
                        v-if="rentalDetail.status === 'REQUESTED'"
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >

                       {{
                    $t(
                      'components.vehicleRentalsManagement.details.headline.accept'
                    )
                  }}
                      
                      </t-dropdown-item>
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'reject',
                          })
                        "
                        v-if="rentalDetail.status === 'REQUESTED'"
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >
                      {{
                    $t(
                      'components.vehicleRentalsManagement.details.headline.reject'
                    )
                  }}
                      </t-dropdown-item>
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            action: 'deliver',
                          })
                        "
                        v-if="rentalDetail.status === 'ACCEPTED'"
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >
                       {{
                    $t(
                      'components.vehicleRentalsManagement.details.headline.markAsDelivered'
                    )
                  }}
                      
                        
                      </t-dropdown-item>
                      <t-dropdown-item
                        @click="
                          onPerformAction({
                            id: rentalDetail.id,
                            user: rentalDetail.rider,
                            action: 'force-complete-open',
                          })
                        "
                        v-if="
                          rentalDetail.status !== 'REQUESTED' &&
                            rentalDetail.status !== 'ACCEPTED' &&
                            rentalDetail.status !== 'TRIP_COMPLETED' &&
                            rentalDetail.status !== 'CANCELLED' &&
                            rentalDetail.status !== 'REJECTED'
                        "
                        class="block px-3 py-1.5 cursor-pointer text-oOrange hover:bg-orange-100"
                      >

                       {{
                    $t(
                      'components.vehicleRentalsManagement.details.headline.forceComplete'
                    )
                  }}
                        
                      </t-dropdown-item>
                    </div>
                  </template>
                </t-dropdown>
              </div>
            </template>
            <template v-else>
              <StatusPillButton
                :status="rentalDetail.status"
                :rawData="rentalDetail"
                @rental-refund="$emit('rental-fee-refund')"
                @extension-refund="$emit('extension-fee-refund')"
              />
            </template>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="rentalDetail.rider"
      class="
        flex flex-col
        items-start
        justify-start
        w-full
        px-4
        py-3
        mb-2
        space-y-5
        bg-white
        rounded-md
        shadow
        md:space-y-0 md:items-center md:flex-row md:justify-between
        responsive-header-section
      "
    >
      <div class="flex items-center w-full space-x-3 md:w-1/3">
        <DisplayAvatar
          :avatar="rentalDetail.rider.profile_pic"
          :width="`68px`"
          :height="`68px`"
        />
        <div>
          <h2 class="font-bold text-oDark text-14px">
            {{ rentalDetail.rider.full_name || '--' }}
          </h2>

          <div class="flex items-center mt-1 text-gray-500 text-14px">
            <i class="mr-2 text-lg fas fa-phone fa-flip-horizontal" />
            <span>{{ rentalDetail.rider.phone_number || '--' }}</span>
          </div>
        </div>
      </div>

      <div
        class="
          grid
          w-full
          grid-cols-2
          gap-y-3
          md:w-2/3 md:grid-cols-3 md:gap-y-0
        "
      >
        <!-- <div class="">
          <h2 class="font-bold text-gray-500 text-14px">
           {{
                    $t(
                      'components.vehicleRentalsManagement.details.secondHeadline.tripID'
                    )
                  }}
                        
          </h2>

          <router-link
            v-if="rentalDetail.trip"
            :to="{
              name: 'ViewTrip',
              params: { id: rentalDetail.trip.id },
            }"
            target="_blank"
          >
            <h4 class="font-bold text-oLink text-14px">
              {{ $truncate(rentalDetail.trip.id, -5, '#') }}
            </h4>
          </router-link>
          <h4 v-else class="font-bold text-oDark text-14px">--</h4>
        </div> -->

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px">
           {{
                    $t(
                      'components.vehicleRentalsManagement.details.secondHeadline.invoiceID'
                    )
                  }}
          
          
          </h2>
          <h4 class="font-bold text-oDark text-14px">
            {{ getInvoiceId }}
          </h4>
        </div>

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px">
           {{
                    $t(
                      'components.vehicleRentalsManagement.details.secondHeadline.transactionDate'
                    )
                  }}
          
          </h2>
          <h4 class="font-bold text-oDark text-14px">
            {{ getInvoiceDate }}
          </h4>
        </div>

        <div class="">
          <h2 class="font-bold text-gray-500 text-14px">{{
                    $t(
                      'components.vehicleRentalsManagement.details.secondHeadline.vehicleQR'
                    )
                  }}</h2>

          <router-link
            v-if="rentalDetail.vehicle"
            :to="{
              name: 'ViewVehicleProfile',
              params: { id: rentalDetail.vehicle.id },
            }"
            target="_blank"
          >
            <h4 class="font-bold text-oLink text-14px">
              {{ rentalDetail.vehicle.qr_code || '--' }}
            </h4>
          </router-link>
          <h4 v-else class="text-blue-500 text-14px">--</h4>
        </div>
      </div>
    </section>

    <div class="grid w-full grid-cols-1 md:grid-cols-5">
      <div class="col-span-1 md:col-span-3">
        <trip-google-map-view
          :rental-detail="rentalDetail"
          :detail="tripDetail"
          :onTripStatusText="onTripStatusText"
          :onTripStatusVariant="onTripStatusVariant"
          ref="tripGmapView"
          id="tripGmapView"
        />
      </div>

      <div class="col-span-1 md:col-span-2">
        <trip-cost-view
          :detail="tripDetail"
          :rentalRawData="rentalDetail"
          :symbol="symbol"
        />
      </div>
    </div>

    <div class="grid w-full grid-cols-1 md:grid-cols-5 mt-3">
      <div class="col-span-1 md:col-span-3">
        <!-- notes -->
        <TripNoteView :id="rentalDetail.id" />
        <!-- /notes -->
      </div>

      <!-- tags -->
      <div class="col-span-1 md:col-span-2">
        <TripTagView :id="rentalDetail.id" />
      </div>
      <!-- /tags -->
    </div>

    <RentalTimeline :rentalDetail="rentalDetail" ref="rentalTimeline" />
    <section>
      <EdgeStack
        :id="tripAction.esId"
        :busy="tripAction.busy"
        :exit-button="{ text: this.$t('components.stepNavigation.cancel'), hidden: true }"
        :footer-class="{
          fixed: 'flex justify-end items-center px-6 pt-2 pb-5 w-full h-auto',
          append: '',
        }"
      >
        <template #header>
          <div class="text-xl font-bold">{{ tripAction.title }}</div>
        </template>

        <template #footer="{ close }">
          <div class="flex items-center gap-5">
            <AppButton text="Cancel" variant="secondary" @click="close()" />

            <AppButton
              text="Confirm"
              :disabled="getClickableStatus"
              :isLoading="tripAction.processing"
              @click="
                onPerformAction({
                  id: tripAction.primaryKey,
                  action: 'force-complete-execute',
                })
              "
            />
          </div>
        </template>

        <template #default>
          <div class="flex items-center px-3">
            <div class="w-14">
              <div
                class="flex items-center justify-center bg-gray-200 rounded-full w-9 h-9"
              >
                <img
                  :src="tripAction.data.user.profile_pic"
                  class="object-contain rounded-full w-9 h-9"
                />
              </div>
            </div>

            <div class="flex items-center justify-between w-full py-4 border-b">
              <div>
                <h5
                  v-text="tripAction.data.user.full_name"
                  class="text-base text-black"
                />
                <h6
                  v-text="'UserID: ' + $truncate(tripAction.data.user.id, -5)"
                  class="text-sm tracking-wide"
                  style="color: #797979"
                />
              </div>

              <div
                class="text-right text-blue-600"
                v-text="`RentID: ${$truncate(tripAction.primaryKey, -5)}`"
              />
            </div>
          </div>

          <div class="flex justify-end  my-2 px-3" v-if="isRentalTimeExtended">
            <div
              class="max-w-md py-4 bg-gray-200 border rounded-md px-3"
              style="min-width: 350px; background: #F3F3F3;"
            >
              <section class="flex items-center justify-between gap-4 ">
                <div class="-mt-6">Extended Duration (mins)</div>

                <div class="w-24">
                  <AppInput
                    v-model="tripAction.models.extended_duration_in_mins"
                    type="number"
                    step="1"
                  />
                </div>
              </section>

              <section class="py-2 border-t border-gray-300">
                <div class="flex items-center justify-between py-1">
                  <div class="w-full">
                    Fee ({{ tripAction.models.extended_duration_in_mins }} mins
                    x
                    {{
                      getTripActionCharges.currency_symbol +
                        getTripActionCharges.rent_time_extension_fees_per_minute
                    }}
                    )
                  </div>

                  <div>
                    {{
                      getTripActionCharges.currency_symbol +
                        getTripActionCharges.trip_amount
                    }}
                  </div>
                </div>
              </section>

              <section class="py-2 border-t border-gray-300">
                <div class="flex items-center justify-between  py-1">
                  <div class="w-full">
                    {{
                    $t(
                      'components.vehicleRentalsManagement.details.cost.subTotal'
                    )
                  }}
                    
                  </div>

                  <div>
                    {{
                      getTripActionCharges.currency_symbol +
                        getTripActionCharges.sub_total_amount
                    }}
                  </div>
                </div>
                <div class="flex items-center justify-betweenpy-1">
                  <div class="w-full">
                    Vat ({{ getTripActionCharges.tax_percentage }}%)
                  </div>

                  <div>
                    {{
                      getTripActionCharges.currency_symbol +
                        getTripActionCharges.vat_amount
                    }}
                  </div>
                </div>
              </section>

              <section class="pt-3 border-t border-gray-300">
                <div class="flex items-center justify-between">
                  <div class="w-full">
                  {{
                    $t(
                      'components.vehicleRentalsManagement.details.cost.total'
                    )
                  }}
                   
                  </div>

                  <div class=" text-3xl ">
                    {{
                      getTripActionCharges.currency_symbol +
                        getTripActionCharges.total_amount
                    }}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="flex px-3 py-3">
            <div class="w-14">
              <div
                class="flex items-center justify-center rounded-full w-9 h-9"
              >
                <img src="@/assets/icon/note.svg" />
              </div>
            </div>
            <div class="w-full">
              <h5 class="text-base text-black" v-text="`Reason (Required)`" />
              <AppInput
                type="richselect"
                rules="required"
                name="reason"
                placeholder="Select a reason"
                value-attribute="value"
                text-attribute="text"
                :options="forceCompleteReason"
                hide-search-box
                v-model="tripAction.models.force_complete_reason"
              />
            </div>
          </div>

          <div class="flex px-3 py-3">
            <div class="w-14">
              <div
                class="flex items-center justify-center rounded-full w-9 h-9"
              >
                <img src="@/assets/icon/note.svg" />
              </div>
            </div>
            <div class="w-full">
              <h5 class="text-base text-black" v-text="`Remarks (Required)`" />
              <AppInput type="textarea" v-model="tripAction.models.remarks" />
            </div>
          </div>
        </template>
      </EdgeStack>
    </section>
  </div>
</template>

<script>
import TripGoogleMapView from './blocks/TripGoogleMapView'
import TripCostView from './blocks/TripCostView'
// import TripPointView from './blocks/TripPointView'
import TripNoteView from './blocks/note/TripNoteView'
import TripTagView from './blocks/TripTagView'

import { mapGetters } from 'vuex'
// import ExportTripBlock from '@/composites/trip/details/blocks/ExportTripBlock.vue'

import RentalTimeline from '@/composites/vehicle-rental/details/RentalTimeline.vue'
import { SocketConfig } from '@/config/SocketConfig'
import { getUTCAwareTime, xMan } from '@/utils'
import EdgeStack from '@/components/modals/EdgeStack'
import ReconnectingWebsocket from 'reconnectingwebsocket'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { EventBus } from '@/utils/EventBus'
import { useEndpoints } from '@/composables'
export default {
  components: {
    TripGoogleMapView,
    TripCostView,
    TripNoteView,
    TripTagView,
    TDropdownItem,
    EdgeStack,
    // ExportTripBlock,
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
    AcceptModal: () => import('@/composites/vehicle-rental/modals/AcceptModal'),
    RejectModal: () => import('@/composites/vehicle-rental/modals/RejectModal'),
    CancelModal: () => import('@/composites/vehicle-rental/modals/CancelModal'),
    DeliverModal: () =>
      import('@/composites/vehicle-rental/modals/DeliverModal'),
    StatusPillButton: () =>
      import('@/composites/vehicle-rental/shared/StatusPillButton'),
    RentalTimeline,
  },
  props: {
    rentalDetail: {
      required: false,
      default: null,
    },
    tripDetail: {
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getInvoiceId() {
      return this.$truncate(this.rentalDetail?.invoice?.id, -5, '#') || '--'
    },
    getInvoiceDate() {
      return this.rentalDetail?.invoice?.date
        ? this.getUTCAwareTime(this.rentalDetail?.invoice?.date)
        : '--'
    },
    getStatusVariant() {
      return this.colors[this.rentalDetail.status] || 'blue'
    },
    getStatusText() {
      return this.texts[this.rentalDetail.status] || '--'
    },
    isRentalTimeExtended() {
      if (this.tripAction.data.pricing_info.is_time_extended) {
        return true
      } else {
        return false
      }
    },
    getClickableStatus() {
      if (
        this.tripAction.models.force_complete_reason &&
        this.tripAction.models.remarks
      ) {
        return false
      } else {
        return true
      }
    },

    getTripActionCharges() {
      const p = this.tripAction.data.pricing_info
      const t = this.tripAction.models.extended_duration_in_mins

      const trip_amount_raw =
        Number(p.rent_time_extension_fees_per_minute) * Number(t)

      const trip_amount = Number(trip_amount_raw).toFixed(2)

      const sub_total_amount = Number(trip_amount).toFixed(2)

      let vat_amount_raw = p.tax_percentage
        ? (p.tax_percentage / 100) * trip_amount
        : 0

      let vat_amount = Number(vat_amount_raw).toFixed(2)

      let total_amount = (
        Number(sub_total_amount) + Number(vat_amount)
      ).toFixed(2)

      return { ...p, trip_amount, sub_total_amount, vat_amount, total_amount }
    },
  },
  data() {
    return {
      currentRentId: null,
      showAcceptModal: false,
      showRejectModal: false,
      showCancelModal: false,
      showDeliverModal: false,
      onTripDurationStr: null,
      colors: {
        REQUESTED: 'yellow',
        ACCEPTED: 'blue',
        REJECTED: 'red',
        CANCELLED: 'red',
        TRIP_COMPLETED: 'green',
        DELIVERED: 'purple',
      },
      texts: {
        REQUESTED: 'Pending',
        ACCEPTED: 'Accepted',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        TRIP_COMPLETED: 'Completed',
        DELIVERED: 'Rent Started',
      },
      symbol: '$',
      intervalid: null,
      pauseIntervalid: null,
      sockListener: null,
      onTripStatusText: 'Rent Started',
      onTripStatusVariant: 'purple',
      renderOnTripStatus: true,
      tripAction: {
        busy: false,
        esId: 'rentActionES',
        title: 'Force Complete Rent',

        primaryKey: '',
        processing: false, //

        models: {
          extended_duration_in_mins: 0, // in min
          force_complete_reason: '',
          remarks: '',
        },

        data: {
          user: {
            id: '--',
            full_name: '--',
            role_name: '--',
            profile_pic: 'https://via.placeholder.com/150',
          },

          pricing_info: {
            extended_duration_in_mins: 0,
            currency_symbol: '~',
            rent_time_extension_fees_per_minute: 0,
            tax_percentage: 0,
            estimated_extended_cost: 0,
            estimated_extension_tax_amount: 0,
            is_time_extended: false,
          },
        },
      },
      forceCompleteReason: [
        {
          text: this.$t('components.vehicleRentalsManagement.forceCompleteReason.vehicleLockingIssue'),
          value: 'VEHICLE_LOCKING_ISSUE',
        },
        {
          text: this.$t('components.vehicleRentalsManagement.forceCompleteReason.vehicleBatteryDied'),
          value: 'VEHICLE_BATTERY_DIED',
        },
        {
          text: this.$t('components.vehicleRentalsManagement.forceCompleteReason.vehicleInactive'),
          value: 'VEHICLE_INACTIVE',
        },
        {
          text: this.$t('components.vehicleRentalsManagement.forceCompleteReason.appIssues'),
          value: 'APP_ISSUES',
        },
        {
          text: this.$t('components.vehicleRentalsManagement.forceCompleteReason.other'),
          value: 'OTHER',
        },
      ],
    }
  },
  // watch: {
  //   onTripDurationStr(updated) {
  //     console.log('tmrender = ', updated.data.duration)
  //   },
  // },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    console.log('symbol = ', this.symbol)
    if (this.rentalDetail.status === 'O') {
      console.log('Starting Bike Noti Listener')
      this.startListener()
      console.log('Refresh timer')
      // this.refreshTimer()
      this.intervalid = setInterval(this.refreshTimer, 1000)
      if (this.rentalDetail.is_paused) {
        let pauseData = this.rentalDetail.pause_entries.pop()
        this.pauseIntervalid = setInterval(() => {
          this.refreshPauseTimer(pauseData)
        }, 1000)

        this.onTripStatusText = 'On Pause'
        this.onTripStatusVariant = 'yellow'
      } else {
        this.onTripStatusText = 'Rent Started'
        this.onTripStatusVariant = 'purple'
      }
    }
  },
  beforeDestroy() {
    this.removeListener()
    this.removeOnTripTimer()
  },
  methods: {
    getUTCAwareTime,
    removeOnTripTimer() {
      if (this.intervalid) clearInterval(this.intervalid)
      if (this.pauseIntervalid) clearInterval(this.pauseIntervalid)
    },
    initRentActionData() {
      this.tripAction.primaryKey = ''
      this.tripAction.processing = false

      this.tripAction.models = {
        extended_duration_in_mins: 0, // in min
        force_complete_reason: '',
        remarks: '',
      }
      this.tripAction.data.user = {
        id: '--',
        full_name: '--',
        role_name: '--',
        profile_pic: 'https://via.placeholder.com/150',
      }
      this.tripAction.data.pricing_info = {
        extended_duration_in_mins: 0,
        currency_symbol: '~',
        rent_time_extension_fees_per_minute: 0,
        tax_percentage: 0,
        estimated_extended_cost: 0,
        estimated_extension_tax_amount: 0,
        is_time_extended: false,
      }
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeNotifications +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = () => {
        console.log('Opened Noti Connection...')
      }
      this.sockListener.onclose = () => {
        console.log('Closed Noti Connection...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket as the was closed'
        )
        this.sockListener = null
      }
    },
    onTripStatusforceRerender() {
      // Remove my-component from the DOM
      this.renderOnTripStatus = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderOnTripStatus = true
      })
    },
    onMessageHandler(message) {
      console.log('Incoming --> ', message)
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_noti') return

      let bikeId = payload.bike_id
      if (!bikeId) return
      if (this.rentalDetail.bike.id !== bikeId) return

      console.log(`Incoming data for bike ${bikeId} : data -->`, payload.data)
      if ('trip_paused' in payload.data) {
        // console.log('Received pause socket msg', payload.data.trip_paused)
        // console.log('Received pause socket data', {
        //   ...payload.data.pause_data,
        // })
        let tripPaused = payload.data.trip_paused
        let pauseData = { ...payload.data.pause_data }
        this.rentalDetail.is_paused = tripPaused

        if (tripPaused) {
          this.pauseIntervalid = setInterval(() => {
            this.refreshPauseTimer(pauseData)
          }, 1000)
          this.onTripStatusText = 'On Pause'
          this.onTripStatusVariant = 'yellow'
        } else {
          clearInterval(this.pauseIntervalid)
          this.$refs.pauseDuration.innerHTML = '--'
          if (!this.intervalid)
            this.intervalid = setInterval(this.refreshTimer, 1000)
          this.onTripStatusText = 'Rent Started'
          this.onTripStatusVariant = 'purple'
        }
        this.onTripStatusforceRerender()
      }
    },
    goBack() {
      this.$router.push('/vehicle-rentals')
    },
    getCalculatedTimerStartTime() {
      let tripStatus = this.rentalDetail.status
      if (tripStatus == 'O') {
        return new Date(this.rentalDetail.pick_up_time)
      } else if (tripStatus == 'R') {
        return new Date(this.rentalDetail.reservation_start_time)
      } else {
        return null
      }
    },
    getTimerStr(timerStartTime) {
      let now = new Date()
      // console.log('now = ', now)
      // let offsetSecs = now.getTimezoneOffset() * 60
      var diffTimestamp = now - timerStartTime
      // console.log('tmr = ', diffTimestamp)

      var delta = Math.abs(diffTimestamp) / 1000
      // delta += offsetSecs
      var days = Math.floor(delta / 86400)
      delta -= days * 86400
      var hours = Math.floor(delta / 3600) % 24
      delta -= hours * 3600
      var minutes = Math.floor(delta / 60) % 60
      delta -= minutes * 60
      var seconds = Math.floor(delta % 60)

      // console.log(diffTimestamp, delta, days, hours, minutes, seconds)

      let tmStr = ''
      if (days > 0) {
        tmStr += `${days} d `
      }
      if (hours > 0) {
        tmStr += `${hours} h `
      }
      if (minutes > 0) {
        tmStr += `${minutes} m `
      }
      if (seconds > 0) {
        tmStr += `${seconds} s`
      }
      return tmStr
    },
    refreshTimer() {
      var timerStartTime = this.getCalculatedTimerStartTime()
      if (!timerStartTime) {
        return
      }
      let tmStr = this.getTimerStr(timerStartTime)
      // document.getElementById('onTripDurationStrSpan').innerHTML = tmStr
      // this.onTripDurationStr = tmStr
      this.$refs.tripDuration.innerHTML = `${tmStr}`
      // this.$forceUpdate()
      // console.log('tmr = ', tmStr, this.$refs.myDiv)
      // console.log('tmr = ', tmStr)
    },
    refreshPauseTimer(entry) {
      if (!entry.start_time) {
        return
      }
      let tmStr = this.getTimerStr(new Date(entry.start_time))
      // document.getElementById('onTripDurationStrSpan').innerHTML = tmStr
      // this.onTripDurationStr = tmStr
      this.$refs.pauseDuration.innerHTML = `${tmStr}`
      // this.$forceUpdate()
      // console.log('tmr = ', tmStr, this.$refs.myDiv)
      // console.log('tmr = ', tmStr)
    },

    async onPerformAction({ id, action, user } = {}) {
      if (this.$acl.canNotUpdate('trips')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }

      console.log('onPerformAction', id, action)
      this.tripAction.primaryKey = id
      if (action === 'accept') {
        this.currentRentId = id
        this.showAcceptModal = true
        return
      }

      if (action === 'reject') {
        this.currentRentId = id
        this.showRejectModal = true
        return
      }

      if (action === 'deliver') {
        this.currentRentId = id
        this.showDeliverModal = true
        return
      }

      if (action === 'close') {
        this.$edgeStack.close(this.tripAction.esId)

        return
      }
      if (action === 'force-complete-open') {
        this.tripAction.data.user = user
        const url = `/dashboard/rentals/vehicle-rentals/${id}/complete-rental-preload/`

        this.tripAction.busy = true
        this.$edgeStack.open(this.tripAction.esId)
        await this.$http
          .get(url)
          .then((r) => {
            console.log('preLoadRes', r.data)
            this.tripAction.data.pricing_info = {
              ...r.data,
              extended_duration_in_mins: Math.floor(
                r.data.extended_duration_in_mins
              ),
            }
            this.tripAction.models.extended_duration_in_mins = Math.floor(
              r.data.extended_duration_in_mins
            )
          })
          .catch((err) => {
            console.warn('rentActionPreloadErr', err, err.response)
          })
          .finally(() => {
            this.tripAction.busy = false
          })
        return
      }
      if (action === 'force-complete-execute') {
        this.tripAction.processing = true
        const url = useEndpoints.vehicleRental.actions.forceComplete(id)
        const { models } = this.tripAction
        const data = new xMan(models).toFormData()

        if (!this.isRentalTimeExtended) {
          data.delete('extended_duration_in_mins')
        }
        console.log('force-complete-execute', url, data)

        await this.$http
          .post(url, data)
          .then((r) => {
            console.log(r.data)
            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: `Force Complete Rent`,
              text: r.data?.detail ?? `Successfully completed the action.`,
            })

            this.onPerformAction({ action: 'close' })
            this.$emit('force-completed')
          })
          .catch((err) => {
            this.$notify(
              {
                group: 'bottomLeft',
                type: 'error',
                title: `Failed Action [Code: ${err?.response?.status}]`,
                text:
                  err.response?.data?.message ??
                  `Failed to execute force complete.`,
              },
              7000
            )
            console.log('rentActionErr', err, err.data)
          })
          .finally(() => {
            this.tripAction.processing = false
            this.initRentActionData()
          })
      }
    },

    onAcceptModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showAcceptModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onRejectModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showRejectModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onCancelModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showCancelModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onDeliverModalClose(shouldUpdate) {
      this.currentRentId = null
      this.showDeliverModal = false

      if (shouldUpdate) {
        this.$emit('updated')
        this.$refs.rentalTimeline.refreshLogs()
      }
    },
    onRefreshDetails() {
      EventBus.$emit('refresh-rent')
    },
  },
}
</script>

<style lang="scss" scoped>
.group-trip-badge {
  background-color: #2563eb !important;
  color: #fff;
  font-weight: 400 !important;
  font-size: 12px !important;
  text-transform: uppercase;
  padding: 4px 10px;
  border-radius: 14px;
  margin-left: 15px !important;
}

.rental-status-pill-button {
  width: 170px;
  @apply flex items-center justify-between shadow-md rounded-full h-35px;
  @apply px-3 py-0 pl-3 text-center cursor-pointer;

  &.fallback {
    @apply bg-oDark text-white;
  }
  &.blue {
    background-color: #0053ff;
    @apply text-white;
  }
  &.blush {
    @apply bg-oBlush text-white;
  }
  &.purple {
    @apply bg-oPurple text-white;
  }
  &.red {
    @apply bg-oRed text-white;
  }
  &.green {
    @apply bg-oGreen text-white;
  }
  &.yellow {
    @apply bg-oYellow text-oDark;
  }
  &.orange {
    @apply bg-oOrange text-white;
  }
}
</style>
